<template>
  <div id="version"
    style="
      margin-left: auto;
      margin-right: auto;
      margin-top: 60px;
      min-width: 800px;
      width: 50%;
      padding: 20px;
    "
  >
    <div class="columns is-multiline is-size-3">
      <div class="column is-12">
        <img
            class="rise-logo"
          src="/images/rise_logo_red.png"
        />
      </div>
      <div class="column is-4">
        <p class="label-bold">Rise Web App</p>
      </div>
      <div class="column is-8">
        {{ version }}
      </div>

      <div class="column is-4">
        <p class="label-bold">Rise API</p>
      </div>
      <div class="column is-8">
        {{ isLoading ? 'Loading version ... ' : apiVersion }}
      </div>
    </div>
  </div>
</template>

<script>
import { version } from '../constant'
import ApiService from '../services/common/api.service'
export default {
  data() {
    return {
      version: version,
      apiVersion: '0.0.0',
      isLoading: false,
    }
  },
  methods: {
    async loadVersion() {
      try {
        this.isLoading = true
        let response = await ApiService.get(
          '/api/space-roketin/back-end/version'
        )
        this.apiVersion = response.data.version
      } catch (err) {
        console.log(err)
      }

      this.isLoading = false
    },
  },
  mounted() {
    this.loadVersion()
  },
}
</script>
